
export default [
    {
        name: 'tipo',
        label: 'Tipo',
        validations: 'required',
        type: 'select',
        value: null,
        options: [
            'Accidente de trabajo', 
            'Enfermedad laboral', 
            'Ausentismo', 
            'Estructura - Proceso - Resultado'
        ]
    },
    {
        name: 'nombreDelIndicador',
        label: 'Nombre',
        validations: 'required',
        type: 'select',
        value: null,
        options: [
            'Severidad de accidentalidad', 
            'Frecuencia de accidentalidad', 
            'Proporción de accidentes de trabajo mortales', 
            'Prevalencia de la enfermadad laboral', 
            'Incidencia de la enfermedad laboral', 
            'Indicadores de Ausentismo por Incapacidades', 
            'Indicadores de Estructura', 'Indicadores de Proceso', 
            'Indicadores de Resultado'
        ]
    },
    {
        name: 'frecuencia',
        label: 'Frecuencia',
        validations: 'required',
        type: 'select',
        value: null,
        options: [
            {value: 'Diaria', label: 'Diaria'},
            {value: 'Semanal', label: 'Semanal'},
            {value: 'Quincenal', label: 'Quincenal'},
            {value: 'Mensual', label: 'Mensual'},
            {value: 'Bimestral', label: 'Bimestral'},
            {value: 'Trimestral', label: 'Trimestral'},
            {value: 'Cuatrimestre', label: 'Cuatrimestre'},
            {value: 'Semestre', label: 'Semestre'},
            {value: 'Anual', label: 'Anual'},
        ]
    },
    {
        name: 'conocedorDelResultado',
        label: 'Persona que debe conocer el resultado',
        validations: 'required',
        type: 'text',
        value: null,
    },
    {
        name: 'fuenteDeInformacion',
        label: 'Fuente de la información',
        validations: 'required',
        type: 'text',
        value: null,
    },
    {
        name: 'formulaOMetodoDeCalculo',
        label: 'Fórmula o método de cálculo',
        validations: 'required',
        type: 'text',
        value: null,
    },
    {
        name: 'metaOlimiteDelIndicador',
        label: 'Meta o limite del indicador',
        validations: 'required',
        type: 'number',
        value: null,
    },

    {
        name: 'responsableDeLaMedicion',
        label: 'Responsable de la medición',
        validations: 'required',
        type: 'text',
        value: null,
    },


]