<script>
    export default
    {
        name: 'ShowDataModel',
        props: ['data','model', 'title'],
        data(){
            return {
                toggle: false
            }
        },
        computed: {
            mappingInfo(){
                let newInfo = this.data.map( element => {
                    let newItem = {}
                    newItem.label = element.label
                    newItem.name = element.name
                    newItem.value = this.model[element.name]

                    return newItem
                })

                return newInfo
            }
        }
    }
</script>

<template>
    <b-list-group>
        <b-list-group-item @click="toggle = !toggle" class="title" v-if="title">
            <b>{{ title }}</b>
        </b-list-group-item>
        <b-collapse v-model="toggle">
            <b-list-group-item v-for="element in mappingInfo" :key="element.name">
                <b>{{ element.label }}: {{ element.value }}</b>
            </b-list-group-item>
        </b-collapse>
    </b-list-group>
</template>

<style scoped>

.title{
    background-color: rgba(0,0,0,0.05);
    cursor: pointer;
}
</style>